<template>
  <div>
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable" @change="setData">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>

    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
         <el-form-item class="currency">
            <div>
            <label for="currencySelect" class="custom-label">Currency Types</label>
            </div>
            <el-select
              id="currencySelect"
              v-model="field.validations.currency"
              filterable
              default-first-option
              @change="currencyVariable"
            >
              <el-option
                v-for="item in CurrencyTypes"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                <span style="float: left">{{ item.value }}</span>
                <span
                  style="
                    float: right;
                    color: var(--el-text-color-secondary);
                    font-size: 13px;
                  "
                  >{{ item.name }}</span
                >
              </el-option>
            </el-select>
            
          </el-form-item>
        </el-col>
        <el-col :span="12"> 
    <field-filled-by :field="field" />
  </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <field-attributes :field="field" />
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" />
        </el-col>
      </el-row>

      <div class="mt-1"></div>
    </el-form>

    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Global Variable">
              <el-select
                v-model="field.global_variable_id"
                @change="setGlobalVariable"
              >
                <el-option
                  v-for="(globalVariable, index) of allGlobalVariables"
                  :key="index"
                  filterable
                  :label="globalVariable.label"
                  :value="globalVariable._id"
                >
                  <span style="float: left">{{ globalVariable.label }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    globalVariable.input_type
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <is-field-required :field="field" />
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    FieldAttributes: () => import("./FieldAttributes"),
  },
  props: ["field"],
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },
  data() {
    return {
      validations: [],

      CurrencyTypes: [
        {
          value: "USD",
          name: "$",
        },

        {
          value: "CAD",
          name: "$",
        },
        {
          value: "AUD",
          name: "$",
        },
        {
          value: "INR",
          name: "₹",
        },
        {
          value: "EUR",
          name: "€",
        },
      ],
    };
  },

  mounted() {
    this.fetchGlobalVaribales();
  },
  methods: {
    setData() {
      if (!this.field.is_global_variable) {
        this.field.options = [];
        this.field.global_variable_id = null;
      }
    },
    async fetchGlobalVaribales() {
      let params = {
        get_all: true,
        input_types: ["CURRENCY"],
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },

    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        (x) => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
      this.field.validations.currency = globalVariable.currency;
    },
    currencyVariable() {
      if (this.field.validations.currency == "INR") {
        this.field.validations.locale = "en-IN";
      } else if (this.field.validations.currency == "USD") {
        this.field.validations.locale = "en-US";
      } else {
        this.field.validations.locale = "en-IE";
      }
    },
  },
};
</script>

<style lang="scss">
.currency:before {
  content:"*";
  color:red;
  position: absolute;
   margin-left: 107px;
 margin-top:4px;
}
</style>